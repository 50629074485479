@media (min-width: 930px) {
  .cricket-page-main-container{
    background-color: solid black;
    height: 1000px;
    width: 100%;
    background-size: cover;
}
.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.header-bg-container{
background-color: #111;
height: 50px;
width: 100%;
margin-top: 40px;
justify-self: center;
border-radius: 8px;
display: flex;
flex-direction: row;
justify-content: space-around;
}
.calendar-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 8px;
  margin-left: 10px;
}
.calendar-button:hover {
  background-color: #ff6b00;
  border: none;
}
.modal-content{
  width: 50%;
}
.calendar-icon {
  color: #555;
}

.live-score-element{
    font-size: 30px;
    font-weight: 900;
    color: white;
    font-style: oblique;
}
.fsize{
  font-size: x-small;
  color: #aaa;
}
.fontSize{
  font-size: small;
}

.gameIcon{
  width: 20px;
  height: 20px;
  margin: 5px;
}
.sports-element{
    font-size: 20px;
    margin-top: 10px;
    font-style: oblique;
    color: #aaa;
    font-weight: 600;
    font-size: 19px;
}
.sports-container{
    width: 600px;
    margin-left: 50px;
    margin-top: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;

    justify-content: space-around;
}
input:focus{
  outline: none;
}
/* .football-element{
    background-color: #222;
    height: 25px;
    width: max-content;
    margin: auto;
    border-radius: 15px;
} */
/* .football-ele{
    color: #aaa;
    padding: 10px;
    font-family:  sans-serif;
    font-weight: 600;
    font-style:oblique;
} */
.cursor{
  cursor: pointer;
}
.card-body{
  padding: 5px;
  background-color: #222;
  border-radius: 10px;
}
.card-body:hover{
  background-color: #424949;
  border-radius: 10px;
}
:hover.football-ele {
  background-color:rgb(202, 202, 202);
  padding: 10px;
    font-family:  sans-serif;
    font-weight: 600;
    font-style:oblique;
    color: black;
    border-radius: 2.5rem;
}
.scores-matches-bg-container{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.countrys-search-container{
    /* width: 200px; */
    border-style: solid;
  border-color: #222;
  /* margin-left: 50px; */
  border-radius: 8px;
  overflow:auto;
}
.counties-display-container{
  background-color: black;
  text-align: center;
  /* margin: 10px; */
  color: white;
  padding: 5px;
  /* height: 40px; */
  border-radius: 8px;
}

.region{
  border-radius: 10px;
}

.regionHover:hover{
  background-color: #424949;
  cursor: pointer;
  border-radius: 5px;
  text-align: start;
}
/* 1024 */
.calendar{
  position: absolute;
  margin-left: 410px;
  margin-top: 125px;
}

/* 425 */
/* .calendar{
  position: absolute;
  margin-left: 295px;
  margin-top: 115px;
} */
* {
    box-sizing: border-box;
  }
  
  /* Style the search field */
  form.example input[type=text] {
    padding: 10px;
    font-size: 15px;
    border: 1px solid #222;
    /* float: left; */
    color: white;
    width: 100%;
    background: none;
  }
  
  /* Style the submit button */
  form.example button {
    float: left;
    width: 10%;
    padding: 10px;
    background: none;
    color: #aaa;
    font-size: 17px;
    border-bottom: 1px solid grey;
    border-left: none; /* Prevent double borders */
    cursor: pointer;
  }
  
  
  /* Clear floats */
  form.example::after {
    content: "";
    clear: both;
    display: table;
  }

  .sports-details-main-container{
    width: 100%;
    /* max-width: 700px; */
    border-style: solid;
    border-color: #222;
    margin-left: 15px;
    border-radius: 8px;
    /* overflow: auto; */
  }
  .days-container{
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
  }

  .live-anchor-tag{
    background-color: white;
    color:black;
    padding: 5px;
    border-radius: 7px;
    /* font-size: small; */
  }
  .live-anchor-tag:hover {
    background-color: #aaa;
  }
  .link-eff{
    /* background-color:  #aaa; */
    color:#aaa;
    padding: 5px;
    border-radius: 7px;
    /* cursor: pointer; */
  }
  .link-eff:hover {
    background-color: #ff6b00;
    color: #181818;
  }
  
  .active{
    background-color: #ff6b00;
    color: #fff;
  }
  
  .label-day-elements{
    /* color: #dddddd; */
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
  }
  /* .label-day-elements:hover{
    color: #fff;
  }
  .date-elements:hover{
    color: #fff;
  } */
  .date-elements{
    font-size: 10px;
    /* color: #dddddd; */
    font-style: italic;
  }
  .score-details-container{
    margin: 20px;
    padding: 8px;
    background-color: #181818;
    height: cover;
    width: cover;
  
    border-radius: 10px;
  }  
}

@media (max-width:930px) {
  .cricket-page-main-container{
    background-color:#111;
    height: 1000px;
    width: 100%;
    background-size: cover;
}
.region{
  border-radius: 10px;
}
.fontSize{
  font-size: small;
}
.regionHover:hover{
  background-color: #424949;
  cursor: pointer;
  border-radius: 5px;
  text-align: start;
}
.header-container{
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.header-bg-container{
background-color: #111 ;
height: 50px;
min-width: 300px;
max-width: 900px;
margin-top: 40px;
justify-self: center;
border-radius: 8px;
display: flex;
flex-direction: row;
justify-content: space-around;
}
.calendar-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 8px;
  margin-left: 10px;
}
.calendar-button:hover {
  background-color: #ff6b00;
  border: none;
}
.modal-content{
  width: 50%;
}
.calendar-icon {
  color: #555;
}
.live-score-element{
    font-size: 30px;
    font-weight: 900;
    color: white;
    font-size: 20px;
    text-align: center;
    font-style: italic;
}
.card-body{
  padding: 5px;
  background-color: #222;
  border-radius: 10px;
}
.card-body:hover{
  background-color: #424949;
  border-radius: 10px;
}
.sports-element{
    display: none;
}
.gameIcon{
  width: 15px;
  height: 20px;
  margin: 5px;
}
.sports-container{
    min-width: 100px;
    max-width: 1300px;
    margin-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.football-element{
    background-color: #222;
    max-width: 360px;
    height: 25px;
    width: max-content;
    border-radius: 15px;
}
.football-ele{
    color: white;
    padding: 10px;
    font-family:  serif;
    font-size: 14px;
    font-style:oblique;
}
.scores-matches-bg-container{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.countrys-search-container{
    display: none;
}
.cursor{
  cursor: pointer;
}
.sports-details-main-container{
  /* height: 600px; */
  width: 100%;
  /* max-width: 600px; */
  border-style: solid;
  border-color: #181815;
  margin: 20px;
  border-radius: 8px;
  /* overflow: hidden;  */
  margin: auto;
}
.days-container{
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.main{
  width: 100%;
}

.fsize{
  font-size: x-small;
  color: #aaa;
}

.live-anchor-tag{
  background-color: white;
  color:black;
  padding: 5px;
  border-radius: 7px;
  margin: 4px;
  /* font-size: small; */
}
.live-anchor-tag:hover {
  background-color: #aaa;
}
.link-eff{
  /* background-color:  #aaa; */
  color:#aaa;
  padding: 5px;
  border-radius: 7px;
  /* cursor: pointer; */
}
.link-eff:hover {
  background-color: #ff6b00;
  color: #181818;
}

.active{
  background-color: #ff6b00;
  color: #fff;
}

.label-day-elements{
  /* color: white; */
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}
.date-elements{
  font-size: 10px;
  /* color: white; */
  font-style: italic;
}
.score-details-container{
  margin: 20px;
  background-color: #424949;
  height: cover;
  width: cover;
  border-radius: 10px;
}
/* 768 to 930 */
/* .calendar{
  position: absolute;
  margin-left: 345px;
  margin-top: 115px;
} */
}

@media (min-width: 768px) and (max-width: 930px) {
  /* min-768 to max-930 */
  .calendar{
    position: absolute;
    margin-left: 335px;
    margin-top: 115px;
  }
}

@media (max-width:768px) {
  .t-hide{
    display: none;
  }
  .cricket-page-main-container{
    background-color:#111;
    height: 1000px;
    width: 100%;
    background-size: cover;
}
.header-container{
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.header-bg-container{
background-color: #111 ;
height: 50px;
min-width: 300px;
max-width: 900px;
margin-top: 40px;
justify-self: center;
border-radius: 8px;
display: flex;
flex-direction: row;
justify-content: space-around;
}
.live-score-element{
    font-size: 30px;
    font-weight: 900;
    color: white;
    font-size: 20px;
    text-align: center;
    font-style: italic;
}
.calendar-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50%;
  padding: 8px;
  margin-left: 10px;
}
.calendar-button:hover {
  background-color: #ff6b00;
  border: none;
}

.modal-content{
  width: 50%;
}

.calendar-icon {
  color: #555;
}

.fsize{
  font-size: x-small;
  color: #aaa;
}

.region{
  border-radius: 10px;
}
.fontSize{
  font-size: small;
}
.regionHover:hover{
  background-color: #424949;
  cursor: pointer;
  border-radius: 5px;
  text-align: start;
}
.sports-element{
    display: none;
}
.card-body{
  padding: 5px;
  background-color: #222;
  border-radius: 10px;
}
.card-body:hover{
  background-color: #424949;
  border-radius: 10px;
}
.sports-container{
    min-width: 100px;
    max-width: 1300px;
    margin-left: 20px;
    padding-right: 50px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.football-element{
    background-color: #222;
    max-width: 360px;
    height: 25px;
    width: max-content;
    border-radius: 15px;
}
.football-ele{
    color: white;
    padding: 10px;
    font-family:  sans-serif;
    font-size: 14px;
    font-style:oblique;
}
.scores-matches-bg-container{
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}
.countrys-search-container{
    display: none;
}
.cursor{
  cursor: pointer;
}
  .sports-details-main-container{
    /* height: 600px; */
    width: 100%;
    /* max-width: 600px; */
    border-style: solid;
    border-color: #181815;
    margin: 20px;
    border-radius: 8px;
    /* overflow: hidden;  */
    margin:auto;
  }
  .days-container{
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .live-anchor-tag{
    background-color: white;
    color:black;
    padding: 5px;
    border-radius: 7px;
    /* font-size: small; */
  }
  .live-anchor-tag:hover {
    background-color: #aaa;
  }
  .active{
    background-color: #ff6b00;
    color: #fff;
  }
  
  .label-day-elements{
    /* color: white; */
    font-size: 14px;
    font-weight: 600;
    font-style: italic;
  }
  .date-elements{
    font-size: 10px;
    /* color: white; */
    font-style: italic;
  }

  .score-details-container{
    margin: 20px;
    background-color: #222;
    height: cover;
    width: cover;
    border-radius: 10px;
  }
  /* 768 */
.calendar{
  position: absolute;
  margin-left: 280px;
  margin-top: 114px;
}
}

@media (max-width: 500px) {
   /* min-500 */
  .calendar{
    position: absolute;
    margin-left: 175px;
    margin-top: 125px;
  }
}

@media ( max-width:420px){
  .sports-details-main-container{
    width: 100%;
    overflow: hidden;
  }
  .cursor{
    cursor: pointer;
  }
  .calendar-button {
    background-color: #f0f0f0;
    border: none;
    border-radius: 50%;
    padding: 8px;
    margin-left: 10px;
  }
  .calendar-button:hover {
    background-color: #ff6b00;
    border: none;
  }
  
  .modal-content{
    width: 50%;
    position: absolute;
    top: 130px;
    right: 0;
  }
  .calendar-icon {
    color: #555;
  }
   /* max-420 */
.calendar{
  position: absolute;
  margin-left: -100px;
  margin-top: 25px;
}
  
}
.fsize{
  font-size: x-small;
  color: #aaa;
}
.team_summary{
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  font-style: italic;
  margin-left: 17rem;
    margin-top: 2rem;
    height: 30px;
}
.team_squad{
  color: #fff;
  font-size: 26px;
  font-weight: 600;
  font-style: italic;
  margin-left: 5rem;
    margin-top: 2rem;
    height: 30px;
}
.team_members{
  color: #aaa;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
  margin-left: 6rem;
    margin-top: 0rem;
    height: 30px;
}
.team1{
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  font-style: italic;
  margin-left: 8rem;
    margin-top: 2rem;
    height: 30px;
}
