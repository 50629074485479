@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");
:root {
  font-size: calc(0.7em + 0.3vw);
}

body {
  font-family: serif;
  color: #eff5fa;
  
}

.nav__top {
  grid-area: navbar;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 1rem 0;
}
.nav__top__item {
  padding: 0 1rem;
  font-size: 1.15rem;
}
.nav__top__item.main-navigation {
  -webkit-box-flex: 1;
          flex: 1;
}
.nav__top__item:not(:first-child) {
  border-left: 1px solid #efefef;
}
.nav__left {
  grid-area: sidebar;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
  border-right: 1px solid #efefef;
  padding: 0 1rem;
  overflow: hidden;
}
.nav__left__item {
  padding: 1rem 0;
}
.nav__left__item:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
.nav__left__toggle button {
  margin-left: 0.3rem;
  margin-top: 0.3rem;
  font-weight: normal;
}
.nav__left__toggle button svg {
  width: 27px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.nav__left .btn--add {
  margin: 2rem auto;
}
.nav__left.opened .nav__left__toggle button svg {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.nav {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.nav .nav-link {
  padding: 0.75rem 1rem;
  color: #8b939a;
  display: inline-block;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-decoration: none;
}
.nav .nav-link.active {
  color: #613cea;
  font-weight: 500;
}
.nav .nav-link:hover {
  color: rgba(97, 60, 234, 0.7);
}
.nav--vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column;
}

.main-navigation .nav-link {
  font-weight: 500;
}
.main-navigation .active {
  position: relative;
  color: inherit;
  font-weight: bold;
}
.main-navigation .active:before {
  content: "";
  display: inline-block;
  width: 50%;
  border-top: 4px solid #613cea;
  border-radius: 2px;
  position: absolute;
  top: -1.3rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.notifications {
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  fill: #a3a9b0;
}

.user {
  display: -webkit-box;
  display: flex;
}
.user__profile-picture {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.user__profile-picture img {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.user__info {
  margin: 0.2rem 0 0 0.75rem;
}
.user__name {
  font-size: 0.875rem;
  margin: 0 0 0.2em;
  font-weight: 500;
  color: #626972;
}
.user__balance {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: baseline;
          align-items: baseline;
}
.user__balance__dollars {
  font-size: 1.15rem;
  font-weight: 500;
  margin: 0 0.5rem 0 0;
}
.user__balance__currency {
  font-size: 0.875rem;
  color: #a3a9b0;
  margin: 0;
}

.secondary-navigation {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  width: 3.8125rem;
}
.secondary-navigation .nav {
  -webkit-box-align: start;
          align-items: flex-start;
}
.secondary-navigation .nav-link {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  color: #d2d5d8;
  text-decoration: none;
  white-space: nowrap;
}
.secondary-navigation .nav-link svg {
  width: 27px;
}
.secondary-navigation .nav-link span {
  padding-left: 3rem;
  -webkit-transition: padding-left 0.3s;
  transition: padding-left 0.3s;
}
.secondary-navigation .active {
  position: relative;
  color: #613cea;
}
.secondary-navigation .active::before {
  content: "";
  display: block;
  height: 120%;
  border-right: 3px solid #613cea;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: -1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.secondary-navigation.opened {
  width: 200px;
}
.secondary-navigation.opened .nav-link span {
  padding-left: 1rem;
}

.dashboard {
  display: grid;
  grid-area: main;
  grid-template-columns: auto 1fr;
  grid-gap: 2rem;
  margin-top: 30px;
  grid-template-areas: "col-left col-right";
  padding: 2rem;
}
.dashboard h2 {
  margin-top: 2rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  font-size: 1.1rem;
}
.dashboard h2 .btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1;
  color: #a3a9b0;
}

.card {
  border: 1px solid #efefef;
  border-radius: 10px;
}
.card > * {
  padding: 2rem;
}
.card__header {
  border-bottom: 1px solid #efefef;
}
.card__footer {
  border-top: 1px solid #efefef;
}
.card__footer--link {
  padding: 0;
}
.card__footer--link a {
  display: block;
  padding: 1.5rem 1.75rem;
  text-align: center;
  color: #a3a9b0;
  text-decoration: none;
  border-radius: 0 0 10px 10px;
}
.card__footer--link a:hover {
  color: #613cea;
  text-decoration: underline;
  font-weight: 600;
}
.card__item {
  padding: 1.5rem 2rem;
}
.card__item:not(:last-child) {
  border-bottom: 1px solid #efefef;
}

.btn {
  display: inline-block;
  /* padding: 0.75rem 1.5rem; */
  line-height: 1.5;
  border: 0;
  /* background-color: #efefef; */
  border-radius: 7px;
  font-weight: 500;
  color: #a3a9b0;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.btn.active{
  background-color: #ff6b00;
  color: #fff;
  border: none;
}
.btn:hover:not(.btn--primary):not(.btn--secondary) {
  /* background-color: #d6d6d6; */
  color: #fff;
}
.btn--primary {
  background-color: #613cea;
  color: white;
}
.btn--secondary {
  background-color: white;
  color: #613cea;
}
.btn--primary, .btn--secondary {
  -webkit-transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, box-shadow 0.3s;
  transition: transform 0.3s, box-shadow 0.3s, -webkit-transform 0.3s;
}
.btn--primary:hover, .btn--secondary:hover {
  -webkit-transform: translateY(-3px);
          transform: translateY(-3px);
  box-shadow: 0 3px 6px rgba(65, 24, 219, 0.16);
}
.btn--icon {
  padding: 0.6rem 0.8rem;
}
.btn--icon svg {
  width: 20px;
  height: 25px;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
}
.btn--toggle {
  border: 1px solid #e2e2e2;
  padding: 0.5rem 0.65rem;
}
.btn--round, .btn--round-lg {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  width: 0.875rem;
  height: 0.875rem;
  padding: 0;
  background-color: #a3a9b0;
  color: white;
}
.btn--round svg, .btn--round-lg svg {
  width: 50%;
  height: 90%;
}
.btn--round-lg {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.btn--add {
  background-color: #613cea;
}
.btn--no-bg {
  background-color: rgba(0, 0, 0, 0);
}

.featured-live__title {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
}
.featured-live__stage {
  margin-right: 1.5rem;
}
.featured-live__stage h3 {
  font-size: 1rem;
  margin: 0 0 0.15rem;
}
.featured-live__stage small {
  color: #8b939a;
}
.featured-live__score {
  margin: 1rem 0 0.5rem;
}
.featured-live__actions {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
}
.featured-live__actions > *:not(:last-child) {
  margin-right: 0.5rem;
}
.featured-live__actions > *:not(:first-child) {
  -webkit-box-flex: 1;
          flex: 1;
}

.tag {
  display: -webkit-inline-box;
  display: inline-flex;
  -webkit-box-align: center;
          align-items: center;
  border-radius: 5px;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  color: #a3a9b0;
}
.tag--red {
  color: #e1223c;
  background-color: rgba(225, 34, 60, 0.2);
}
.tag--green {
  color: #52b49c;
  background-color: rgba(82, 180, 156, 0.2);
}
.tag--icon svg {
  fill: currentColor;
  margin-right: 0.3rem;
}

.rating {
  border: 1px solid rgba(163, 169, 176, 0.1);
  padding: 0.5em 0.8em;
  vertical-align: middle;
  position: relative;
}
.rating--primary {
  color: white;
  background-color: #613cea;
}
.rating--up {
  border-color: rgba(82, 180, 156, 0.1);
}
.rating--up:before {
  border-bottom: 6px solid;
  top: -0.375rem;
  -webkit-transform: translate(-50%, -100%);
          transform: translate(-50%, -100%);
}
.rating--down {
  border-color: rgba(225, 34, 60, 0.1);
}
.rating--down:before {
  border-top: 6px solid;
  bottom: -0.375rem;
  -webkit-transform: translate(-50%, 100%);
          transform: translate(-50%, 100%);
}
.rating--up:before, .rating--down:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  position: absolute;
  left: 50%;
}
.rating:not([class*="--"]) {
  background-color: rgba(163, 169, 176, 0.1);
}

.close {
  color: inherit;
  display: contents;
}

.score {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-pack: justify;
          justify-content: space-between;
  width: 100%;
}
.score--vertical {
  -webkit-box-pack: start;
          justify-content: flex-start;
}
.score__team {
  text-align: center;
}
.score__team span {
  display: block;
  font-weight: 500;
  padding-top: 0.25rem;
}
.score__team img {
  width: 40px;
}
.score__team--vertical {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  -webkit-box-flex: 1;
          flex: 1;
}
.score__team--vertical:first-child {
  -webkit-box-pack: end;
          justify-content: flex-end;
}
.score__team--vertical span {
  white-space: nowrap;
  font-size: inherit;
}
.score__team--vertical img {
  width: 32px;
  margin: 0 0.5rem;
}
.score__info {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
          justify-content: center;
  -webkit-box-align: center;
          align-items: center;
  padding: 0 1rem;
  margin: 1rem 0;
}
.score__info p {
  margin: 0;
}
.score__result {
  text-align: center;
  width: 100%;
  font-size: 1.75rem;
  margin: 0;
}
.score__result--not-started {
  color: #a3a9b0;
}
.score__result--vertical {
  letter-spacing: 0.2em;
  font-size: inherit;
  -webkit-box-flex: 0;
          flex: 0 0 auto;
  width: auto;
}
.score__time {
  padding-top: 0.5rem;
  color: #a3a9b0;
}
.score__separator {
  color: #a3a9b0;
}
.score__loser {
  color: #a3a9b0;
}

.league {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.league__logo {
  width: 35px;
  height: auto;
  align-self: flex-start;
  margin-right: 0.75rem;
}
.league__title h3 {
  margin: 0 0 0.25rem;
  font-size: 1rem;
}
.league__title small {
  color: #a3a9b0;
}
.league__number {
  margin-left: auto;
  color: #cfd2d5;
  font-size: 1.15rem;
}

.hero {
  background-color: #613cea;
  padding: 2rem;
  border-radius: 10px;
  position: relative;
}
.hero__title {
  color: white;
  margin: 0 0 0.25rem !important;
  font-weight: 400;
}
.hero__text {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  line-height: 1.7;
  margin-top: 0;
  max-width: 58%;
}
.hero__cta {
  padding-left: 3rem;
  padding-right: 3rem;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.hero__img {
  width: 40%;
  max-width: 350px;
  position: absolute;
  overflow: hidden;
  height: calc(110%);
  top: -10%;
  right: 2rem;
}
.hero__img img {
  width: 100%;
  height: auto;
}

.matches .nav-item:first-child .nav-link {
  padding-left: 0;
}
.matches .nav-item .nav-link {
  padding-top: 0;
  padding-bottom: 0;
}
.matches__nav {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.matches__agenda {
  color: #a3a9b0;
  padding: 0;
}
.matches__data {
  padding: 0;
}
.matches__time {
  color: #6d7680;
  margin-right: 0.5rem;
}
.matches__time--live {
  margin-right: 1rem;
}
.matches__stats {
  padding: 0.4rem 0.5rem;
}
.matches__stats svg {
  width: 15px;
  height: 15px;
}
.matches table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.825rem;
  text-align: center;
  font-weight: 500;
}
.matches thead {
  background-color: rgba(139, 147, 154, 0.05);
  color: #a3a9b0;
}
.matches tbody tr:last-child {
  border: 0;
}
.matches th {
  font-weight: 500;
}
.matches tr {
  border-bottom: 1px solid #efefef;
}
.matches td,
.matches th {
  padding: 1rem 0.25rem;
}
.matches td:first-child,
.matches th:first-child {
  padding-left: 2rem;
  text-align: left;
  white-space: nowrap;
}
.matches td:nth-child(2),
.matches th:nth-child(2) {
  width: 100%;
}
.matches td:last-child,
.matches th:last-child {
  padding-right: 2rem;
}

.switch {
  border-radius: 1rem;
  border: 1px solid #d6d6d6;
  width: 2.5rem;
  background-color: #efefef;
  padding: 0.25rem;
  line-height: 1;
}
.switch:before {
  content: "";
  display: block;
  border-radius: 50%;
  background-color: #613cea;
  height: 1rem;
  width: 1rem;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.switch__label {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  cursor: pointer;
}
.switch__option {
  font-weight: bold;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.switch__input:not(:checked) ~ span:first-of-type {
  color: #132536;
}
.switch__input:not(:checked) ~ span:last-of-type {
  color: #a3a9b0;
}
.switch__input:checked ~ span:last-of-type {
  color: #132536;
}
.switch__input:checked ~ span:first-of-type {
  color: #a3a9b0;
}
.switch__input:checked ~ .switch::before {
  -webkit-transform: translateX(1.5rem);
          transform: translateX(1.5rem);
}

.toggle-buttons label.btn.btn--toggle:hover {
  color: #613cea;
  background-color: rgba(97, 60, 234, 0.2);
  border-color: rgba(97, 60, 234, 0.3);
}
.toggle--button:checked + label {
  color: #613cea;
  background-color: rgba(97, 60, 234, 0.1);
  border-color: rgba(97, 60, 234, 0.5);
}

.checkbox {
  display: block;
  padding-left: 1.5rem;
  position: relative;
  margin-bottom: 1rem;
}
.checkbox::before {
  content: "";
  display: block;
  background-color: #efefef;
  border: 1px solid #d6d6d6;
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  position: absolute;
  top: -0.1rem;
  left: 0rem;
}
.checkbox::after {
  content: "✓";
  text-align: center;
  color: white;
  font-size: 0.875rem;
  display: block;
  background: #613cea;
  border: 1px solid #613cea;
  width: 1rem;
  height: 1rem;
  border-radius: 5px;
  position: absolute;
  top: -0.1rem;
  left: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}

input[type="text"] {
  border: 1px solid #d6d6d6;
  border-radius: 7px;
  padding: 0.25rem 0.5rem;
}
input[type="text"]::-webkit-input-placeholder {
  font-weight: 500;
  color: #a3a9b0;
  font-family: "Roboto", sans-serif;
}
input[type="text"]::-moz-placeholder {
  font-weight: 500;
  color: #a3a9b0;
  font-family: "Roboto", sans-serif;
}
input[type="text"]:-ms-input-placeholder {
  font-weight: 500;
  color: #a3a9b0;
  font-family: "Roboto", sans-serif;
}
input[type="text"]::-ms-input-placeholder {
  font-weight: 500;
  color: #a3a9b0;
  font-family: "Roboto", sans-serif;
}
input[type="text"]::placeholder {
  font-weight: 500;
  color: #a3a9b0;
  font-family: "Roboto", sans-serif;
}
input[type="checkbox"]:checked + .checkbox::after {
  opacity: 1;
}

.bet__match {
  margin: 0 0 0.5rem;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
}
.bet__match .btn {
  margin-left: auto;
}
.bet__team {
  font-weight: bold;
}
.bet__team__separator {
  color: #8b939a;
  padding: 0.5rem;
}
.bet__outcome__state {
  color: #cccfd3;
}
.bet__outcome__rating {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
          align-items: center;
  margin: 0.5rem 0 0;
  background-color: rgba(97, 60, 234, 0.1);
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  border-radius: 5px;
  color: #613cea;
  font-weight: bold;
}
.bet__outcome__rating .rating {
  font-size: 0.7rem;
  font-weight: normal;
}

.invoice {
  grid-area: invoice;
  background-color: #f3f5f9;
  font-size: 0.875rem;
  width: 0;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
}
.invoice__wrapper {
  width: 20rem;
  padding: 1.2rem 1.5rem 2.5rem;
  border-left: 1px solid #efefef;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.invoice.opened {
  width: 23rem;
}
.invoice article {
  -webkit-filter: drop-shadow(0 0px 7px rgba(163, 169, 176, 0.3));
          filter: drop-shadow(0 0px 7px rgba(163, 169, 176, 0.3));
}
.invoice__section {
  --bg-color: white;
  padding: 1.5rem 2rem;
  position: relative;
}
.invoice__section:first-child:after, .invoice__section:nth-child(2):after {
  content: "";
  width: 85%;
  border-top: 2px dashed #f3f5f9;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.invoice__title {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: end;
          align-items: flex-end;
  -webkit-box-pack: justify;
          justify-content: space-between;
  color: #8b939a;
  margin-top: 0;
  font-size: 1.15rem;
  font-weight: 500;
  margin-bottom: 1.5rem;
}
.invoice .switch__option {
  font-size: 1.15rem;
}
.invoice__type {
  background: linear-gradient(135deg, transparent 7px, var(--bg-color) 0) top left, linear-gradient(225deg, transparent 7px, var(--bg-color) 0) top right, linear-gradient(315deg, transparent, var(--bg-color) 0) bottom right, linear-gradient(45deg, transparent, var(--bg-color) 0) bottom left;
  background-image: radial-gradient(circle at 0 0, transparent 0, var(--bg-color) 0), radial-gradient(circle at 100% 0, transparent 0, var(--bg-color) 0), radial-gradient(circle at 100% 100%, transparent 8px, var(--bg-color) 9px), radial-gradient(circle at 0 100%, transparent 8px, var(--bg-color) 9px);
  background-size: 52% 52%;
  background-repeat: no-repeat;
}
.invoice__type:before {
  content: "";
  background: radial-gradient(circle, transparent, transparent 50%, #fff 50%, #fff 100%) -7px -8px/16px 16px repeat-x;
  width: 100%;
  height: 6px;
  position: absolute;
  top: -5px;
  left: 0;
}
.invoice__bet {
  background: linear-gradient(135deg, transparent 7px, var(--bg-color) 0) top left, linear-gradient(225deg, transparent 7px, var(--bg-color) 0) top right, linear-gradient(315deg, transparent 7px, var(--bg-color) 0) bottom right, linear-gradient(45deg, transparent 7px, var(--bg-color) 0) bottom left;
  background-image: radial-gradient(circle at 0 0, transparent 8px, var(--bg-color) 9px), radial-gradient(circle at 100% 0, transparent 8px, var(--bg-color) 9px), radial-gradient(circle at 100% 100%, transparent 8px, var(--bg-color) 9px), radial-gradient(circle at 0 100%, transparent 8px, var(--bg-color) 9px);
  background-size: 53% 53%;
  background-repeat: no-repeat;
}
.invoice__payment {
  background: linear-gradient(135deg, transparent 7px, var(--bg-color) 0) top left, linear-gradient(225deg, transparent 7px, var(--bg-color) 0) top right, linear-gradient(315deg, transparent, var(--bg-color) 0) bottom right, linear-gradient(45deg, transparent, var(--bg-color) 0) bottom left;
  background-image: radial-gradient(circle at 0 0, transparent 8px, var(--bg-color) 9px), radial-gradient(circle at 100% 0, transparent 8px, var(--bg-color) 9px), radial-gradient(circle at 100% 100%, transparent 0, var(--bg-color) 0), radial-gradient(circle at 0 100%, transparent 0, var(--bg-color) 0);
  background-size: 50% 50%;
  background-repeat: no-repeat;
}
.invoice__payment:after {
  content: "";
  z-index: 1;
  background: radial-gradient(circle, transparent, transparent 50%, var(--bg-color) 50%, var(--bg-color) 100%) -7px -8px/16px 16px repeat-x;
  width: 100%;
  height: 6px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: absolute;
  top: 100%;
  left: 0;
}
.invoice__payment__amount {
  display: -webkit-box;
  display: flex;
}
.invoice__payment__amount label {
  margin-right: 0.5rem;
}
.invoice__payment__amount [type="text"] {
  -webkit-box-flex: 1;
          flex: 1;
  width: 0;
}
.invoice__payment__options__title {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
          justify-content: space-between;
  color: #8b939a;
  margin-top: 2rem;
}
.invoice__payment__recap {
  display: -webkit-box;
  display: flex;
  margin-top: 1.5rem;
}
.invoice__payment__recap__rating {
  padding-right: 1.5rem;
  margin-right: 1.5rem;
  border-right: 1px solid #efefef;
}
.invoice__payment__recap__rating .rating {
  font-size: 0.75rem;
}
.invoice__payment__recap__title {
  margin: 0 0 0.5rem;
  white-space: nowrap;
}
.invoice__payment__recap__winnings__amount {
  color: #52b49c;
  margin: 0;
  font-size: 1.35rem;
}
.invoice__submit {
  --bg-color: rgba(163, 169, 176, 0.2);
  background-color: var(--bg-color);
  padding: 2rem;
}
.invoice__submit .btn {
  width: 100%;
  box-shadow: 0 2px 10px rgba(97, 60, 234, 0.4);
}
.invoice__submit:after {
  content: "";
  z-index: 1;
  background: radial-gradient(circle, transparent, transparent 50%, var(--bg-color) 50%, var(--bg-color) 100%) -7px -8px/16px 16px repeat-x;
  width: 100%;
  height: 6px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: absolute;
  top: 100%;
  left: 0;
}
.invoice__assistance {
  padding: 2rem 1.5rem;
  border-top: 1px solid #e2e4e6;
}
.invoice__assistance__button.btn {
  width: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  justify-content: space-around;
  font-weight: normal;
  color: #a3a9b0;
  font-size: 1.15rem;
  border-radius: 2.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  box-shadow: 1px 2px 2px rgba(65, 24, 219, 0.1);
}
.invoice__assistance__button.btn svg {
  width: 1.5rem;
  color: #613cea;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.credits {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-auto-columns: auto;
  grid-gap: 0.5rem;
  -webkit-box-align: center;
          align-items: center;
  padding: 1rem 1.25rem;
  border-radius: 1.75rem;
  background-color: white;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  z-index: 3;
}
.credits__label {
  font-weight: 500;
  color: #613cea;
}
.credits a {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
          align-items: center;
  height: 30px;
  position: relative;
  color: #a3a9b0;
}
.credits a span {
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 0.75rem;
  border-bottom-right-radius: 0;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  white-space: nowrap;
  background-color: #613cea;
  color: white;
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  position: absolute;
  bottom: calc(100% + 15px);
  right: 10px;
}
.credits a span:after {
  content: "";
  display: block;
  border-top: 10px solid #613cea;
  border-left: 8px solid transparent;
  position: absolute;
  top: 100%;
  right: 0;
}
.credits a:hover span {
  opacity: 1;
}





/* google maps */
.gomap {
  width: 100%;
  height: 100vh;
}


/* start of black box below google maps */
@import url("https://fonts.googleapis.com/css?family=Cousine");
* {
  box-sizing: border-box;
}

/*Challenge colours*/
:root {
  --black: #000;
  --pink: #ff1ead;
  --white: #fff;
}

::-moz-selection {
  background: var(--pink);
  color: var(--white);
}

::selection {
  background: var(--pink);
  color: var(--white);
}



.title h1 {
  font-size: 1.75rem;
  margin: 1.8em;
}

.container{
  max-width: 1024px !important;
}

/* .container {
  background: black;
  box-shadow: 0px 10px 50px 5px #77004b;
  display: flex;
  height: 28rem;
  text-align: left;
  width: 50rem;
} */

.container__content {
  color: var(--white);
  margin: 2em 3em;
  padding: .5rem;
}

.content__social {
  align-content: flex-end;
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}

.content__social svg {
  margin-left: .95rem;
}

.container__content hr {
  background-color: var(--pink);
  border: 0 none;
  color: var(--pink);
  height: 1px;
  margin-bottom: 1.5rem;
}

.container__content h1 {
  font-size: 1.5rem;
}

.content__icon {
  position: relative;
}

.content__icon1 {
  top: -2.25rem;
}

.content__icon2 {
  top: -2.25rem;
}

.content__icon3 {
  top: .75rem;
}

.content__address,
.content__web,
.content__phone,
.container__content span {
  display: inline-block;
  font: .9rem/1.25 Cousine, monospace;
  padding-left: 0.5em;
  transition: color 0.5s;
}
.content__address:hover,
.content__web:hover,
.content__phone:hover,
.container__content span:hover {
  color: var(--pink);
}

.credits {
  font-size: 0.8rem;
  margin: 50px;
  text-align: center;
}

.credits a {
  color: #000;
  text-decoration: none;
}

.credits a:hover {
  text-decoration: underline;
}
/* end of black box below google maps */


